import React from 'react';

function MillsCard(props) {
  return (
    <>
      <div className="mills-card">
        <img src={props.img} alt={props.name} />

        <h2>{props.name}</h2>
      </div>
    </>
  );
}

export default MillsCard;
