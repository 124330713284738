import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Randomizer(props) {
  const millsAds = [
    {
      image: '/ads/benjas.jpg',
      link:
        'http://benjathai.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'Benjas Thai Garden',
      class: 'webFormat',
    },
    {
      image: '/ads/blvd.jpg',
      link:
        'https://www.blvdhome.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'BLVDHome',
      class: 'webFormat',
    },
    {
      image: '/ads/george-painted.jpg',
      link:
        'https://www.google.com/search?source=hp&ei=j3M9YMLqGtnNtQb2oq6AAg&iflsig=AINFCbYAAAAAYD2BnwyaXuwIxMj62AbQOYnNbPreVnzy&q=goerges+corner+pub+and+the+painted+pony&btnK=Google+Search&oq=how+fast+are+sharks&gs_lcp=Cgdnd3Mtd2l6EAMyBwgAEEYQ-wEyAggAMgIIADIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeOg4ILhCxAxDHARCjAhCTAjoICAAQsQMQgwE6CwguELEDEMcBEKMCOgUIABCxAzoICC4QxwEQrwE6AgguOggILhCxAxCDAVDOCVikHWDxHmgAcAB4AIABnQGIAb0RkgEEMS4xOJgBAKABAaoBB2d3cy13aXo&sclient=gws-wiz&ved=0ahUKEwiC7r3kmpDvAhXZZs0KHXaRCyAQ4dUDCAk&uact=5#btnK=Google%20Search/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'Georges Corner Resturant and Pub and The Painted Pony',
      class: 'webFormat',
    },
    {
      image: '/ads/kayenta.jpg',
      link:
        'http://www.kayentautah.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'Kayenta Homes',
      class: 'webFormat',
    },
    {
      image: '/ads/PBS.jpg',
      link:
        'http://www.kayentautah.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'PBS Utah',
      class: 'webFormat',
    },
    {
      image: '/ads/SGMT-Tuacahn-Ad-Feb21.jpg',
      link:
        'https://www.sgmusicaltheater.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'St. George Musical Theater',
      class: 'webFormat',
    },
    {
      image: '/ads/Shoppes_at_Zion_FINAL.jpg',
      link:
        'https://theshoppesatzion.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'The Shoppes at Zion',
      class: 'webFormat',
    },
    {
      image: '/ads/Sullivan_Homes.jpg',
      link:
        'https://sullivan-homes.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'Sullivan Homes',
      class: 'webFormat',
    },
    {
      image: '/ads/UniversityCU.jpg',
      link:
        'https://www.ucreditu.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=shakespeare',
      alt: 'University Federal Credit Union',
      class: 'webFormat',
    },
  ];

  const [randomAd] = React.useState(
    millsAds[Math.floor(Math.random() * millsAds.length)]
  );

  return (
    <>
      <hr />
      <br />
      <OutboundLink
        href={randomAd.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </OutboundLink>
      <br />
      <br />
      <hr />
      <br />
    </>
  );
}

export default Randomizer;
