import React from 'react';

function clientRandomizer(props) {
  const millsAds = [
    {
      image: '/sponsors/60Anniversary.jpg',
      link: 'https://bard.org/',
      alt: 'Utah Shakespeare Festival 60th Anniversary',
      class: 'webFormat',
    },
    {
      image: '/sponsors/2022ShakespeareSeason.jpg',
      link: 'https://bard.org/',
      alt: 'Utah Shakespeare Festival 2022 Season',
      class: 'webFormat',
    },
    {
      image: '/sponsors/Canyon_Media.jpg',
      link: 'https://canyonmedia.net/',
      alt: 'Canyon Media',
      class: 'webFormat',
    },
    {
      image: '/sponsors/CherryCreek.jpg',
      link: 'https://www.cherrycreekmedia.com/',
      alt: 'Cherry Creek Media',
      class: 'webFormat',
    },
    {
      image: '/sponsors/ConnectWithUs.jpg',
      link: 'https://bard.org/',
      alt: 'Utah Shakespeare Festival Social Media',
      class: 'webFormat',
    },
    {
      image: '/sponsors/LondonTrip.jpg',
      link: 'https://bard.org/',
      alt: 'London Trip',
      class: 'webFormat',
    },
    {
      image: '/sponsors/ThankYouMediaSponsors.jpg',
      link: 'https://bard.org/',
      alt: 'Media Sponsors',
      class: 'webFormat',
    },
    {
      image: '/sponsors/USF_Guild.jpg',
      link: 'https://www.bard.org/festival-guild',
      alt: 'Utah Shakespeare Festival Guild',
      class: 'webFormat',
    },
  ];

  const randomAd = millsAds[Math.floor(Math.random() * millsAds.length)];

  return (
    <>
      <hr />
      <br />
      <a href={randomAd.link} target="_blank" rel="noopener noreferrer">
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </a>
      <br />
      <br />
      <hr />
    </>
  );
}

export default clientRandomizer;
