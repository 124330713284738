export default {
  colors: {
    primary: '#FB8500',
    background: '#023047',
    sidebar: {
      background: '#023047',
      link: '#999',
      heading: '#aaa',
      linkActive: '#13131A',
      itemActive: '#F5F5FA',
      footer: '#A8A8B3',
    },
  },
};
