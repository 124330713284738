import { Link } from 'gatsby';
import ClientRandomizer from "../../../../src/docs/components/clientrandomizer";
import MillsCard from "../../../../src/docs/components/millscard";
import Break from "../../../../src/docs/components/break";
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import * as React from 'react';
export default {
  Link,
  ClientRandomizer,
  MillsCard,
  Break,
  Zoom,
  React
};