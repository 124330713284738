import React from 'react';
import { Global, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { lighten } from 'polished';

export default function GlobalStyle() {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        @import url('https://use.typekit.net/pfj3ewl.css');

        *,
        *::after,
        *::before {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        *::selection {
          background: ${lighten('0.35', '#f8f8f2')}!important;
        }
        nav ul li a {
          font-size: 1.1rem !important;
        }
        body {
          font-size: 16px;
          font-family: 'ff-tisa-web-pro', sans-serif;
          background-color: ${theme.colors.background};
          text-rendering: optimizelegibility;
        }

        h1 {
          font-size: 32px;
          color: #41a5a4;
          font-weight: normal;
          margin-bottom: 24px;
        }

        h2 {
          font-size: 2rem;
          color: #41a5a4 !important;
        }

        header {
          h2 {
            padding-bottom: 3px !important;
            font-weight: bold !important;
            color: white !important;
            text-align: center;
            width: 75%;
          }
        }

        footer {
          background-color: #41a5a4;
          color: white;
          text-align: center;
          padding: 0px !important;
          border-top: 10px solid #5e81ac;
          p {
            color: white !important;
            background: none;
          }
        }

        h3 {
          font-size: 1.5rem;
          margin-bottom: 0px !important;
        }

        h4 {
          font-size: 16px;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
          color: #f8f8f2;
          margin: 24px 0 16px 0;
          font-weight: normal;
        }
        ${'' /* h2 {
          opacity: 0;
        } */}

        @keyframes fade-in {
          from {
            opacity: 0;
            transform: scale(0.7, 0.7);
          }
          to {
            opacity: 1;
          }
        }
        .fade-in-element {
          animation: fade-in 1.4s;
          opacity: 1;
        }

        p {
          color: #e5e9f0;
          font-size: 1.25rem;
          line-height: 28px;
          margin-bottom: 16px;
          font-weight: 300;
          padding: 22px;
          border-radius: 5px;
          background: #366a99;
        }

        small {
          color: #f8f8f2;
        }

        code.inline-code {
          display: inline-block;
          vertical-align: middle;
          line-height: 1;
          padding: 0.2em 0.2em 0.3em 0.2em;
          background-color: #44475a;
          color: rgba(248, 248, 242);
          font-size: 14px;
          border-radius: 3px;
          font-feature-settings: 'clig' 0, 'calt' 0;
          font-variant: no-common-ligatures no-discretionary-ligatures
            no-historical-ligatures no-contextual;
        }

        a {
          color: #f8f8f2;
          font-weight: bold;

          &:hover {
            color: ${theme.colors.primary}!important;
            transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
            opacity: 1 !important;
          }
        }

        header {
          border-bottom: 1rem solid #41a5a4;
          background-color: #366a99 !important;
          ${'' /* margin-top: 20px; */}
          height: 120px !important;
          button {
            width: 50px;
            height: 50px;
            background-color: #41a5a4 !important;
            svg {
              fill: #fff !important;
              stroke: #fff !important;
            }
          }
        }

        blockquote {
          margin: 0;

          p {
            color: black;
            padding: 1rem;
            background: #f5f5fa;
            border-radius: 5px;
          }
        }

        hr {
          border: 0;
          height: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
          width: 100%;
          margin-bottom: 16px;
          color: #444;
        }

        th,
        td {
          text-align: left;
          padding: 12px;
        }

        tr:nth-of-type(2n) td {
          background-color: ${theme.colors.sidebar.itemActive};
        }

        tr {
          background-color: #ffffff;
        }

        iframe {
          margin-bottom: 16px;
        }

        img {
          max-width: 100%;
          margin: 0 auto;
          display: block;
        }
        .story {
          margin-top: 12px;
        }
        figcaption {
          color: white !important;
          margin-bottom: 30px;
        }
        ul,
        ol {
          color: #f8f8f2;
          padding-left: 15px;
          margin-bottom: 16px;

          li {
            line-height: 28px;
          }
        }

        aside {
          border-right: 1px solid #5e81ac;
        }

        .mills-card {
          width: 100%;
          background-color: #366a99;
          padding: 22px;
          border-radius: 5px;
          h2 {
            margin-bottom: 0px !important;
            em {
              color: #5e81ac !important;
            }
          }
          p {
            padding: 0px !important;
            color: #eceff4;
            background: #366a99;
          }
          em {
            color: #eceff4;
          }
        }

        details {
          background-color: white;
          border-radius: 5px;
          padding: 15px;
          cursor: pointer;
          margin-bottom: 10px;
        }

        #home-list {
          padding-left: 0px;

          li {
            width: 100%;
            background-color: #41a5a4;
            padding: 12px;
            margin-bottom: 22px;
            border-radius: 5px;
            text-align: center;
          }
          a {
            text-decoration: none;
          }
        }

        div.home-list {
        }
        a.home-column {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          background-color: #41a5a4;
          border-radius: 5px;
          text-decoration: none;
          height: 70px;
          padding: 10px;
          margin-bottom: 10px;
        }
        a.home-column:hover {
          color: black !important;
        }

        .webFormat {
          display: block;
          margin: 0 auto;
        }

        .gatsby-highlight {
          position: relative;

          .token {
            font-style: normal !important;
          }
        }

        pre[class*='language-']::before {
          background: #d9d7e0;
          border-radius: 0 0 4px 4px;
          color: #232129;
          font-size: 12px;
          font-family: SFMono-Regular, Menlo, Monaco, Consolas,
            'Liberation Mono', 'Courier New', monospace;
          letter-spacing: 0.075em;
          line-height: 1;
          padding: 0.25rem 0.5rem;
          position: absolute;
          left: 1rem;
          text-align: right;
          text-transform: uppercase;
          top: 0;
        }

        pre[class*='language-'] code {
          font-family: SFMono-Regular, Menlo, Monaco, Consolas,
            'Liberation Mono', 'Courier New', monospace;
          font-variant: no-common-ligatures no-discretionary-ligatures
            no-historical-ligatures no-contextual;
        }

        pre[class~='language-js']::before,
        pre[class~='language-javascript']::before {
          content: 'js';
          background: #f7df1e;
        }

        pre[class~='language-jsx']::before {
          content: 'jsx';
          background: #61dafb;
        }

        pre[class~='language-typescript']::before,
        pre[class~='language-ts']::before {
          content: 'ts';
          background: #294e80;
          color: #fff;
        }

        pre[class~='language-tsx']::before {
          content: 'tsx';
          background: #294e80;
          color: #fff;
        }

        pre[class~='language-graphql']::before {
          content: 'GraphQL';
          background: #e10098;
          color: #fff;
        }

        pre[class~='language-html']::before {
          content: 'html';
          background: #005a9c;
          color: #fff;
        }

        pre[class~='language-css']::before {
          content: 'css';
          background: #ff9800;
          color: #fff;
        }

        pre[class~='language-mdx']::before {
          content: 'mdx';
          background: #f9ac00;
          color: #fff;
        }

        pre[class~='language-shell']::before {
          content: 'shell';
        }

        pre[class~='language-sh']::before {
          content: 'sh';
        }

        pre[class~='language-bash']::before {
          content: 'bash';
        }

        pre[class~='language-yaml']::before {
          content: 'yaml';
          background: #ffa8df;
        }

        pre[class~='language-markdown']::before {
          content: 'md';
        }

        pre[class~='language-json']::before,
        pre[class~='language-json5']::before {
          content: 'json';
          background: linen;
        }

        pre[class~='language-diff']::before {
          content: 'diff';
          background: #e6ffed;
        }

        pre[class~='language-text']::before {
          content: 'text';
          background: #fff;
        }

        pre[class~='language-flow']::before {
          content: 'flow';
          background: #e8bd36;
        }
      `}
    />
  );
}
