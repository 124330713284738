import React from 'react';

function Purchase(props) {
  return (
    <>
      <ul id="home-list" style={{ listStyle: 'none' }}>
        <a
          href="https://secure.bard.org/Online/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <li style={{ backgroundColor: '#97341f' }}>Tickets</li>
        </a>
      </ul>
    </>
  );
}

export default Purchase;
